interface AirportDataModel {
  IATA: string
  _id: string
  _key: string
  _rev: string
  city: string
  country: string
  latitude: number
  longitude: number
  name: string
  timezone_offset: number
}

interface UtilityModel {
  airport: AirportDataModel[]
}

enum UtilityActionType {
  GetAirportList = '⌘➝App➝Landing➝GetAirportList'
}

export type UtilityAction = {
  data: AirportDataModel[],
  type: UtilityActionType.GetAirportList
};

export { UtilityActionType };
export type { UtilityModel };
